import React, { useState } from 'react';
import axios from 'axios';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './Support.css';

export const Support = () => {
  const serverURL = `${process.env.REACT_APP_BACKEND_SERVER_URL}/payment`;
  //const serverURL = `${process.env.REACT_APP_BACKEND_SERVER_URL_LOCAL}/payment`;



  const [volunteerForm, setVolunteerForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const stripe = useStripe();
  const elements = useElements();

  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    setVolunteerForm({
      ...volunteerForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleVolunteerSubmit = (e) => {
    e.preventDefault();
    // Handle form submission for volunteering
    console.log('Volunteer form submitted:', volunteerForm);
    // Reset form after submission
    setVolunteerForm({
      name: '',
      email: '',
      message: '',
    });
  };

  const handleDonateSubmit = async (e) => {
    e.preventDefault()
    const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)
    })

    console.log('sererURL', serverURL)
    if (!error) {
        try {
            const { id } = paymentMethod
            
            const response = await axios.post(serverURL, {
                amount: 2000,
                id: id
            })

            if (response.data.success) {
                console.log("Successful payment")
                setSuccess(true)
            }
        } catch (error ) {
            console.log("Error", error.message)
        }
    }

    if (!error) {
      // Handle successful payment and show confirmation message
      console.log('Payment successful:', paymentMethod);
    } else {
      // Handle payment error
      console.log('Payment error:', error);
    }
  };

  return (
    <div className="support-container">
      <section className="volunteer-section">
        <h2>Volunteer</h2>
        <form className="volunteer-form" onSubmit={handleVolunteerSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={volunteerForm.name} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={volunteerForm.email} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" value={volunteerForm.message} onChange={handleInputChange} required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </section>

      <section className="donate-section">
        <h2>Donate</h2>
        <p>Use the form below to donate:</p>
        {!success ? 
                <form className="donate-form" onSubmit={handleDonateSubmit}>
                <div className="form-group">
                  <label htmlFor="card-element">Card Information:</label>
                  <CardElement
                    id="card-element"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          '::placeholder': {
                            color: '#aaa',
                          },
                        },
                      },
                    }}
                  />
                </div>
                <button type="submit" disabled={!stripe}>
                  Donate with Stripe
                </button>
              </form> : 
            <div>
              <h2>May Allah reward you for donating!</h2>
            </div>
        }
        <div className="donation-info">
          <h3>Other Donation Options:</h3>
          <p>Scotiabank Chequing Account: #0135917</p>
          <p>E-transfer: munawaracenter@gmail.com</p>
        </div>
      </section>
    </div>
  );
};