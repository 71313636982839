import React from 'react';
import './Widget.css';

export const Widget = () => {
  return (
    <div className="widget-container">
      <h2 className="widget-heading">VISION</h2>
      <p className="widget-text">
        Our vision is to establish a dynamic institution that not only imparts quality education but also nurtures strong moral values, fosters a sense of community, and develops responsible global citizens.
      </p>
    </div>
  );
};