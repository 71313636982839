import React from "react";
import { Navbar } from "../../components";
import "./Events.css";
import img1 from "../../assets/img/friday_event_1/img1.jpeg";
import img2 from "../../assets/img/friday_event_1/img2.jpeg";
import img3 from "../../assets/img/friday_event_1/img3.jpeg";
import img4 from "../../assets/img/friday_event_1/img4.jpeg";
import img5 from "../../assets/img/friday_event_1/img5.jpeg";
import img6 from "../../assets/img/friday_event_1/img6.jpeg";
import img7 from "../../assets/img/friday_event_1/img7.jpeg";
import img8 from "../../assets/img/friday_event_1/img8.jpeg";
import img9 from "../../assets/img/friday_event_1/img9.jpeg";

function Events() {
  return (
    <>
      <div className="events">
        <div className="events__section">
          <div className="events__section__description">
            <div className="event-header">
              Weekly Faith Lecture: Nourishing the Soul
            </div>
            <div className="event-details">
              Join us every Friday after <strong>Maghrib</strong> for our
              enriching Weekly Faith Lecture, a special time dedicated to
              deepening your spiritual journey and strengthening your faith.
              <br></br>
            </div>
          </div>
          <div className="events__section__photos">
            <div className="events__section__photos__grid">
              <img src={img1}></img>
              <img src={img2}></img>
              <img src={img3}></img>
              <img src={img4}></img>
              <img src={img5}></img>
              <img src={img6}></img>
              <img src={img7}></img>
              <img src={img8}></img>
              <img src={img9}></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
