import React from "react";
import { Navbar } from "../../components";
import musalla_pic from "../../assets/img/musalla_pic.jpeg";

import "./About.css";

const About = () => {
  return (
    <>
      <div className="about-container">
        <div className="section-header">
          <h2>Services & Facilities</h2>
        </div>
        <div className="service-item">
          <h3>Prayer Facilities</h3>
          <p>
            We provide a tranquil environment for spiritual reflection and
            worship.
          </p>
        </div>
        <div className="service-item">
          <h3>Islamic Education</h3>
          <p>
            We offer Quranic studies, Tajweed classes, Arabic language
            instruction, and Islamic studies. Our aim is to instill a deep
            understanding and love for Islam in our students while fostering a
            sense of identity and purpose.
          </p>
        </div>
        <div className="service-item">
          <h3>Community Outreach</h3>
          <p>
            Through community outreach programs, we actively engage with the
            wider community, promoting brotherhood, empathy, and positive social
            change.
          </p>
        </div>
        <img className="musalla_img" src={musalla_pic} alt="" />
        <div className="section-header">
          <h2>Assalam Alaikum</h2>
          <p>
            Welcome to the Madina Munawara Community Center, a place where faith
            and knowledge converge to create a holistic educational experience.
            We invite you to embark on a journey of spiritual growth, academic
            excellence, and community engagement as we strive to cultivate
            well-rounded individuals rooted in the teachings of Islam.
          </p>
        </div>
        <div className="section-header">
          <h2>Our Vision</h2>
          <p>
            Our vision is to establish a dynamic institution that not only
            imparts quality education but also nurtures strong moral values,
            fosters a sense of community, and develops responsible global
            citizens.
          </p>
        </div>
        <div className="contact-info">
          <h3>For more information, contact:</h3>
          <p>Farhan Nur: (613) 521-0285</p>
          <p>2832D Cedarwood Drive Ottawa, ON – K1V 7R1</p>
          <p>(613) 521 - 0285</p>
          <p>munawaracenter@gmail.com</p>
        </div>
      </div>
    </>
  );
};

export default About;
