import './PrayerTimes.css'
import React, { useEffect, useState } from 'react';
import { FaSun, FaRegMoon, FaMosque } from 'react-icons/fa';
import { BsFillSunriseFill, BsFillSunsetFill, BsFillMoonStarsFill } from 'react-icons/bs';
import axios from 'axios'

export const PrayerTimes = () => {
  const [prayerTimes, setPrayerTimes] = useState([]);
  const [athanTimes, setAthanTimes] = useState({});


  const convert24To12HourTime = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/prayer-times`)
      .then(response => {
        console.log("read from excell sucessfully")
        console.log(response.data)
        for (const [key, value] of Object.entries(response.data)) {
          customTimings[key] = value
          console.log("Custom timings" + key + " " + value)
        }
        setAthanTimes(customTimings)
        //setNotes(response.data)
      })
    fetchPrayerTimes();
  }, []);

  const fetchPrayerTimes = async () => {
    try {
      const date = new Date()
      let month = date.getMonth() + 1; // 0 - indexed
      let year = date.getFullYear()

      const location = `Ottawa,Canada`;
      const method = 2; //URL: https://aladhan.com/prayer-times-api
      const response = await fetch(
        `https://api.aladhan.com/v1/calendarByAddress/${year}/${month}?address=${location}&method=${method}`
      );
      const data = await response.json();
      console.log(`API Response`, data)

      const prayerTimesData = data.data[new Date().getDate() - 1].timings;
      console.log('getting prayer dataaaa')
      console.log(prayerTimesData)
      let prayerTimesDataClean = {}

      for (const [key, value] of Object.entries(prayerTimesData)) {
        console.log(key, value);
        let timeSplit = value.split(" ")
        timeSplit = convert24To12HourTime(timeSplit[0])
        prayerTimesDataClean[key] = timeSplit
      }
      setPrayerTimes(prayerTimesDataClean);
    } catch (error) {
      console.error('Error fetching prayer times:', error);
    }
  };


  const renderIcon = (prayerName) => {
    const iconStyle = {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '8px', // Adjust the spacing between the icon and text
    };

    switch (prayerName) {
      case 'Fajr':
        return (
          <BsFillSunriseFill size={32} color="yellow" style={iconStyle} />
        );
      case 'Sunrise':
        return (
          <FaSun size={32} color="orange" style={iconStyle} />
        );
      case 'Dhuhr':
        return (
          <FaSun size={32} color="orange" style={iconStyle} />
        );
      case 'Asr':
        return (
          <FaSun size={32} color="orange" style={iconStyle} />
        );
      case 'Maghrib':
        return (
          <BsFillSunsetFill size={32} color="red" style={iconStyle} />
        );
      case 'Isha':
        return (
          <FaRegMoon size={32} color="gray" style={iconStyle} />
        );
      case 'Jumuah':
        return (
          <FaMosque size={32} color="orange" style={iconStyle} />
        );
      case 'Midnight':
        return (
          <BsFillMoonStarsFill size={32} color="blue" style={iconStyle} />
        );
      default:
        return null;
    }
  };


  const customTimings = {
    Fajr: '0:00 AM',
    Dhuhr: '00:0 PM',
    Asr: '0:00 PM',
    Maghrib: '0:00 PM',
    Isha: '0:00 PM',
    Jumuah: '0:00 PM',
  };


  return (
    <div className="prayer-times-container">
      <h2>Prayer Times</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Athan</th>
            <th>Iqamah</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(prayerTimes).map((prayerName) => {
            if (prayerName !== 'Sunset' && prayerName !== 'Imsak' && prayerName !== 'Firstthird' && prayerName !== 'Lastthird' && prayerName !== 'Midnight') {
              return (
                <tr key={prayerName}>
                  <td className='icon'>
                    <div className='icon-container'>
                      {renderIcon(prayerName)} <span className='prayer-text'>{prayerName}</span>
                    </div>
                  </td>
                  <td>{prayerTimes[prayerName]}</td>
                  <td>{athanTimes[prayerName]}</td>
                </tr>
              )
            }
          })}

          <tr key="Jumuah">
            <td className='icon'>
              <div className='icon-container'>
                {renderIcon("Jumuah")} <span className='prayer-text'>Jumuah</span>
              </div>
            </td>
            <td>{prayerTimes['Dhuhr']}</td>
            <td>{athanTimes["Jumuah"]}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}