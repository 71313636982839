import React from 'react';
import { Navbar, PrayerTimes, Widget } from '../../components';
import masjid_nabawi_pic from '../../assets/img/masjid_nabawi2.jpg';
import { Link } from 'react-router-dom'

import './Home.css'

const Home = () => {
  return (
    <>
      <div className='home_container'>
        <div className='hero'>

          <div className='hero_container'>
            <img className="hero_img" src={masjid_nabawi_pic} alt=""/>
            <div className='overlay'></div>
            <div className='title'>Madinah Munawara Community Center</div>
            {/* <div className='title'>&quot;Whoever builds a Masjid for (the sake of) Allah, then Allah will build a house for him in Paradise.&quot; [Tirmidhi 318]</div> */}
          </div>
        </div>

        <div className='donate_section'>
          <Link className='donate_section_button' to='/donate'>Donate Today</Link>
        </div>

        <div className='home_content'>
          <PrayerTimes />
          <p className='paragraph1'>
            Madina Munawara Community Centre Is a registered not-for-profit organization. We are working
            towards applying for a charitable status in the near future. Join us as we
            strive to build a strong foundation for a deeprooted connection to Islam.
          </p>
          <div className='widget-container'>
            <Widget />
          </div>
          
          
        </div>

      </div>
      
  </>
    
  );
};

export default Home;
