import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import { ReactComponent as Masjid } from "../../assets/icons/mosque-logo.svg";
import logo2 from "../../assets/icons/icon3.png";
// import masjid_logo from '../../assets/img/masjid_pic7.jpg'

export const Navbar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  return (
    <div className="navbar_container">
      <header>
        {/* <Masjid className="masjid-logo" /> */}
        <img src={logo2} className="masjid-logo"></img>
        <nav ref={navRef}>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/events">Events</Link>
          <Link to="/donate">Support</Link>
          {/* <Link to='/forum'>Community</Link> */}

          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
};
