import React from 'react'
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Support } from "../../components/Support/Support"

const PUBLIC_KEY = `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}` // Switch with live key

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export const StripeContainer = () => {
  return (
    <Elements stripe={stripeTestPromise}>
        <Support />
    </Elements>
  )
}
