import React, {useState, useEffect} from 'react'
import { Navbar } from '../../components'
import axios from 'axios';

const Forum = () => {
    const [comments, setComments] = useState([]);
    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState('');

    const url = "http://localhost:4000/forum/post"

    useEffect(() => {
      axios.get(url).then(data => {
        console.log(data.data)
        setPosts(data.data)
      })
    
      
    }, [])
    

    const handlePost = (e) => {
        console.log(e.target.value);
        setPost(e.target.value);

    }

    const handlePosts = (e) => {
        console.log('cliked', posts)
        axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/v1/forum/post`, 
        {
            data: post
        })
        setPosts([...posts, post])
    }
  return (
    <>

        <div style={{ display: 'block', paddingTop: '80px'}}>
            <input type='text' value={post} onChange={handlePost} style={{ width: '500px', paddingRight: '20px'}}></input>
            <button onClick={handlePosts}>Create Post!</button>
            <h1>POSTS</h1>
            {
                posts.map(post => {
                    return <h3 key={post.id} style={{ color: 'black'}}>{post.post}</h3>
                })}

            {    
                comments.map(comment => {
                    <p>{comment}</p>
                })
            }
        </div>
    </>
  )
}

export default Forum