import React from 'react'
import { PrayerTimes } from '../../components/PrayerTimes/PrayerTimes'

export const Test = () => {
  return (
    <>
        <PrayerTimes />
    </>
  )
}

export default Test;