import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/themes';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Donate from './pages/Donate/Donate';
import Test from './pages/Test/Test';
import Forum from './pages/Forum/Forum';
import Events from './pages/Events/Events';
import { Navbar }  from './components/DynamicNavigation/Navbar';

import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className='App'>
  
  <ThemeProvider theme={theme}>
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/contact" element={<Donate />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/test" element={<Test />} />
        <Route path="/events" element={<Events />} />

      </Routes>
    </Router>
  </ThemeProvider>
  </div>
);

