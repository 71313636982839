import React from "react";
import { Navbar } from "../../components";
import { StripeContainer } from "../../containers";

const Support = () => {
  return (
    <div>
      <StripeContainer />
    </div>
  );
};

export default Support;
